// Generated by Framer (ee31e22)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, SmartComponentScopedContainer, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {fQS5AnTFD: {hover: true}, HfmhS_kRR: {hover: true}, MAI868tdw: {hover: true}};

const cycleOrder = ["MAI868tdw", "HfmhS_kRR", "fQS5AnTFD"];

const serializationHash = "framer-1aiF4"

const variantClassNames = {fQS5AnTFD: "framer-v-1nb7vl6", HfmhS_kRR: "framer-v-7nak8f", MAI868tdw: "framer-v-r6rqb7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "MAI868tdw", Mobile: "HfmhS_kRR", Tablet: "fQS5AnTFD"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, M21ELhWGd: click ?? props.M21ELhWGd, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "MAI868tdw"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, M21ELhWGd, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "MAI868tdw", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapmelwq1 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (M21ELhWGd) {const res = await M21ELhWGd(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-r6rqb7", className, classNames)} data-border data-framer-name={"Desktop"} data-highlight layoutDependency={layoutDependency} layoutId={"MAI868tdw"} onTap={onTapmelwq1} ref={refBinding} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-851cdc4d-9c0e-4081-b1db-89078acb383e, rgb(255, 255, 255))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-1e413a12-0cc5-43ac-8f06-e1cf6beb50f2, rgb(255, 84, 0))", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", boxShadow: "inset 2px -2px 5px 0px rgba(0, 0, 0, 0.25), inset -2px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 5px 0px rgba(0, 0, 0, 0.25)", ...style}} {...addPropertyOverrides({"fQS5AnTFD-hover": {"data-framer-name": undefined}, "HfmhS_kRR-hover": {"data-framer-name": undefined}, "MAI868tdw-hover": {"data-framer-name": undefined}, fQS5AnTFD: {"data-framer-name": "Tablet"}, HfmhS_kRR: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-1g767d6-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"NxoMQpOI1-container"} nodeId={"NxoMQpOI1"} rendersWithMotion scopeId={"Tmb4JROgO"}><Phosphor color={"var(--token-851cdc4d-9c0e-4081-b1db-89078acb383e, rgb(255, 255, 255))"} height={"100%"} iconSearch={"House"} iconSelection={"Play"} id={"NxoMQpOI1"} layoutId={"NxoMQpOI1"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1aiF4.framer-1vcoqxy, .framer-1aiF4 .framer-1vcoqxy { display: block; }", ".framer-1aiF4.framer-r6rqb7 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 100px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 100px; will-change: var(--framer-will-change-override, transform); }", ".framer-1aiF4 .framer-1g767d6-container { flex: none; height: 50%; position: relative; width: 50%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-1aiF4.framer-r6rqb7 { gap: 0px; } .framer-1aiF4.framer-r6rqb7 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-1aiF4.framer-r6rqb7 > :first-child { margin-left: 0px; } .framer-1aiF4.framer-r6rqb7 > :last-child { margin-right: 0px; } }", ".framer-1aiF4.framer-v-7nak8f.framer-r6rqb7 { height: 44px; width: 44px; }", ".framer-1aiF4.framer-v-1nb7vl6.framer-r6rqb7, .framer-1aiF4.framer-v-7nak8f.hover.framer-r6rqb7 { height: 60px; width: 60px; }", ".framer-1aiF4.framer-v-r6rqb7.hover.framer-r6rqb7 { height: 120px; width: 120px; }", ".framer-1aiF4.framer-v-1nb7vl6.hover.framer-r6rqb7 { height: 72px; width: 72px; }", ".framer-1aiF4[data-border=\"true\"]::after, .framer-1aiF4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 100
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HfmhS_kRR":{"layout":["fixed","fixed"]},"fQS5AnTFD":{"layout":["fixed","fixed"]},"iZcRgOBV2":{"layout":["fixed","fixed"]},"fGfieaS_c":{"layout":["fixed","fixed"]},"Vbl4e2rdJ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"M21ELhWGd":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerTmb4JROgO: React.ComponentType<Props> = withCSS(Component, css, "framer-1aiF4") as typeof Component;
export default FramerTmb4JROgO;

FramerTmb4JROgO.displayName = "Play button 2";

FramerTmb4JROgO.defaultProps = {height: 100, width: 100};

addPropertyControls(FramerTmb4JROgO, {variant: {options: ["MAI868tdw", "HfmhS_kRR", "fQS5AnTFD"], optionTitles: ["Desktop", "Mobile", "Tablet"], title: "Variant", type: ControlType.Enum}, M21ELhWGd: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerTmb4JROgO, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})